<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dark dense class="header-row">
      <div class="title">遥感工具平台</div>
      <template v-slot:extension>
        <v-tabs v-model="active">
          <v-tab @click="toPage('/sceneProcess')">主页</v-tab>
          <v-tab @click="toPage('/userManagement')" v-if="role === 'AD'">用户管理</v-tab>
        </v-tabs>
      </template>
    </v-app-bar> -->
    <!--外部项目管理员或普通用户 显示订单修改申请-->
    <div style="position:absolute;right:0" v-if="
        (this.role == 'projectAdmin' && this.referenceCode != '1000') ||
          this.role == 'customer'
      ">
      <v-snackbar absolute v-for="(orderApplication, index) in unhandledOrderApplications" :key="index" color="success"
        class="snackbar" v-model="orderApplication.showSnackbar" :timeout="-1">
        <v-row dense no-gutters>
          <v-col cols="9" class="">
            <span class="font-weight-bold mr-10">项目修改申请</span>
            <v-spacer></v-spacer>
            <span class="caption">{{ orderApplication.snackbarText }}</span>
          </v-col>
          <v-col cols="3" class="d-flex align-center">
            <v-btn @click="
                submitApplicationResult(orderApplication.orderId, 'approved')
              " icon dark fab x-small>
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-btn>
            <v-btn @click="
                submitApplicationResult(orderApplication.orderId, 'refused')
              " icon dark fab x-small>
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>
    </div>
    <!-- 系统管理员或专业人员 显示同意或拒绝的订单申请 -->
    <div style="position:absolute;right:0" v-else-if="this.role == 'proUser' || this.role == 'sysAdmin'">
      <v-snackbar absolute v-for="(orderApplication, index) in orderStatusApplications" :key="index"
        :color="orderApplication.color" class="snackbar" v-model="orderApplication.showSnackbar">
        <v-row dense no-gutters>
          <v-col cols="12" class="">
            <span class="font-weight-bold mr-10">{{
              orderApplication.snackbarTitle
            }}</span>
            <v-spacer></v-spacer>
            <span class="caption">{{ orderApplication.snackbarText }}</span>
          </v-col>
        </v-row>
      </v-snackbar>
    </div>
    <v-main>
      <router-view></router-view>
      <!-- <router-view name="customermain" v-if="role == 'OU'"></router-view>
      <router-view name="projectmain" v-if="role == 'PA'"></router-view> -->
    </v-main>
  </v-app>
</template>

<script>
import commonCfg from "../config/common";
let Url = commonCfg.commonUrl,
  version = commonCfg.urlVersion; // http://192.168.1.109:64011/
let eventSource = null;
let vm = null;
import axios from "axios"; //基于Promise的HTTP客户端
export default {
  name: "Home",
  components: {},
  data () {
    return {
      //active: 0,
      role: "", //用户角色
      username: localStorage.getItem("username"), //登录用户
      referenceCode: "", //邀请码
      unhandledOrderApplications: [], //订单修改申请
      orderStatusApplications: [] //同意或拒绝的订单申请
    };
  },
  async created () {
    this.role = this.$root.role;
    vm = this;
    if (this.role == "customer") {
      //普通用户
      this.selectUnhandledOrderApplications();
    } else if (this.role == "proUser" || this.role == "sysAdmin") {
      //系统管理员或专业人
      this.selectStatusChangedApplications();
    } else if (this.role == "projectAdmin") {
      await this.getReferenceCode();
      //外部项目经理
      if (this.referenceCode != "1000") this.selectUnhandledOrderApplications();
    }
  },
  destroyed () {
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
  },
  methods: {
    // toPage(path) {
    //   if (this.$route.path != path) this.$router.push(path);
    // }
    //获得邀请码
    async getReferenceCode () {
      let res = await axios.get(`${Url}getReferenceCode${version}`);
      console.log(res.data);
      this.referenceCode = res.data;
    },
    //订阅事件 订单修改申请一经提交客户端就会收到一条通知
    selectUnhandledOrderApplications () {
      eventSource = new EventSource(
        `https://remotesensing.live/event/getUnhandledApplications?username=${this.username}`
      );
      console.log(eventSource.readyState);
      // Handler for events without an event type specified
      //eventSource.onmessage = (e) => {

      //};
      // Handler for events of type 'eventType' only
      eventSource.addEventListener("unhandled_applications", e => {
        // Do something - event data will be in e.data
        console.log(e.data);
        let data = JSON.parse(e.data);
        //一段时间未处理后订单自动拒绝 需要从原来订单修改申请里删除已经被拒绝的申请
        let removedIndex = []; //要删除的下标
        for (let i = 0; i < vm.unhandledOrderApplications.length; i++) {
          let applicationIndex = data.findIndex(
            obj => obj.orderId == vm.unhandledOrderApplications[i].orderId
          );
          if (applicationIndex <= -1) {
            removedIndex.push(i);
          }
        }
        for (let index of removedIndex) {
          vm.unhandledOrderApplications.splice(index, 1);
        }
        //加入订单修改申请数组
        for (let application of data) {
          let applicationIndex = vm.unhandledOrderApplications.findIndex(
            obj => obj.orderId == application.orderId
          );
          if (applicationIndex <= -1) {
            application["showSnackbar"] = true;
            application["snackbarText"] = `项目名称: ${application.name}`;
            vm.unhandledOrderApplications.push(application);
          }
        }
      });
      eventSource.onerror = function (e) {
        console.log(e);
        eventSource.close();
      };
    },
    //订阅事件 用户一同意或拒绝订单 系统管理员端就会收到通知结果
    selectStatusChangedApplications () {
      eventSource = new EventSource(
        `https://remotesensing.live/event/getStatusChangedApplications?applicant=${this.username}`
      );
      console.log(eventSource.readyState);
      //eventSource.onmessage = (e) => {

      //};
      eventSource.addEventListener("status_changed_applications", e => {
        console.log(e.data);
        let data = JSON.parse(e.data);
        for (let application of data) {
          //if(vm.orderStatusApplications.findIndex(obj => obj.orderId == application.orderId) <= -1){
          application["showSnackbar"] = true;
          application.status == "approved"
            ? (application["snackbarTitle"] = "用户同意了您的项目修改申请")
            : (application["snackbarTitle"] = "用户拒绝了您的项目修改申请");
          application.status == "approved"
            ? (application["color"] = "success")
            : (application["color"] = "error");
          application["snackbarText"] = `项目名称: ${application.name}`;
          vm.orderStatusApplications.push(application);
          //}
        }
      });
      eventSource.onerror = function (e) {
        console.log(e);
        eventSource.close();
      };
    },
    //同意或拒绝订单修改申请
    submitApplicationResult (orderId, status) {
      this.$dialog
        .confirm({
          text:
            status == "approved"
              ? "同意申请后可以查看修改后的详情。"
              : "15分钟内不处理将自动拒绝申请。",
          title: status == "approved" ? "同意申请?" : "拒绝申请?",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.get(`${Url}updateApplicationStatus${version}`, {
            params: {
              orderId: orderId,
              status: status
            }
          });
          console.log(res.data);
          if (res.data == "ok") {
            let index = vm.unhandledOrderApplications.findIndex(
              obj => obj.orderId == orderId
            );
            vm.unhandledOrderApplications.splice(index, 1);
          }
        });
    }
  }
};
</script>
<style scoped>
/* .header-row {
  background: linear-gradient(
    180deg,
    #1e5799 0,
    #2989d8 50%,
    #207cca 51%,
    #7db9e8
  );
} */
.snackbar {
  z-index: 10000;
  position: relative !important;
}
</style>

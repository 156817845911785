import axios from "axios";
import router from "../router/index";

// http request 拦截器
axios.interceptors.request.use(
  config => {
    //console.log(config)
    if (localStorage.token) {
      if (
        config.url.indexOf("/token/") == -1 &&
        config.url.indexOf("/validate/") == -1
      ) {
        config.headers.Authorization = `Bearer ${localStorage.token}`;
      }
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 401 清除token信息并跳转到登录页面
          //store.dispatch(USER_SIGNOUT)
          if (localStorage.token) {
            document.cookie = "token=''; path=/"; //清除cookie里的token
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
          }
          router.replace({
            path: "/login" //跳转到登录页面
            // query: { redirect: router.currentRoute.fullPath } // 登陆跳转之前页面
          });
      }
    }
    return Promise.reject(error.response.data);
  }
);

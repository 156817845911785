import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import axios from "axios";
import qs from "qs";
import commonCfg from "../config/common";

Vue.use(VueRouter);

const router = new VueRouter({
  //mode: "history",
  //base: process.env.BASE_URL,
  routes: [
    {
      path: "/home",
      name: "Home",
      component: Home,
      children: [
        {
          path: "",
          name: "",
          // beforeEnter: (to, from, next) => {
          //   console.log(to, from, next);
          //   //switch (localStorage.username) {
          //   //  case "pj-admin":
          //   //    next("/projectMain");
          //   //    break;
          //   //  case "rst-admin":
          //   next("/projectMain");//customerMain projectMain
          //   //    break;
          //   //  default:
          //   //    next("/customerMain");
          //   //}
          // },
          component: () => import("../views/main.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("../views/mainPage/projectList.vue"),
              meta: { requireAuth: true }
            }
          ],
          meta: { requireAuth: true } //roles: ["sysAdmin","proUser","projectAdmin","customer"]
        },
        {
          path: "/main",
          name: "Main",
          component: () => import("../views/main.vue"),
          children: [
            {
              path: "",
              name: "",
              component: () => import("../views/mainPage/projectList.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/projectList",
              name: "projectList",
              component: () => import("../views/mainPage/projectList.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/userInfo",
              name: "userInfo",
              component: () => import("../views/mainPage/userInfo.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/message",
              name: "message",
              component: () => import("../views/mainPage/message.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/customer",
              name: "users",
              component: () =>
                import("../views/mainPage/customerManagement.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/userManagement",
              name: "userManagement",
              component: () => import("../views/mainPage/userManagement.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/projectManager",
              name: "projectManager",
              component: () => import("../views/mainPage/projectManager.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/userAuth",
              name: "userAuth",
              component: () => import("../views/mainPage/userAuth.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/accountApply",
              name: "accountApply",
              component: () => import("../views/mainPage/accountApply.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/tools",
              name: "tools",
              component: () => import("../views/mainPage/toolsManagement.vue"),
              meta: { requireAuth: true }
            },
            {
              path: "/license",
              name: "license",
              component: () => import("../views/mainPage/license.vue"),
              meta: { requireAuth: true }
            }
          ],
          meta: { requireAuth: true }
        },
        {
          path: "/detail/:orderId", //-1 代表可以新建
          name: "Detail",
          component: () => import("../views/detail/detail.vue"),
          meta: { requireAuth: true }
        }
        // {
        //   path: "/customerMain",
        //   name: "CustomerMain",
        //   component: () => import("../views/customer/main.vue"),
        //   meta: { requireAuth: true, roles: ["OU"] }
        // },
        // {
        //   path: "/customerOrderDetail/:orderId", //-1 代表可以新建
        //   name: "CustomerOrderDetail",
        //   component: () => import("../views/detail.vue"),
        //   meta: { requireAuth: true, roles: ["OU"] }
        // },
        // {
        //   path: "/projectMain",
        //   name: "ProjectMain",
        //   component: () => import("../views/project/main.vue"),
        //   meta: { requireAuth: true, roles: ["PA"] }
        // },
        // {
        //   path: "/projectDetail/:orderId",
        //   name: "ProjectDetail",
        //   component: () => import("../views/detail.vue"),
        //   meta: { requireAuth: true, roles: ["PA"] }
        // },
        // {
        //   path: "/professorMain",
        //   name: "ProfessorMain",
        //   component: () => import("../views/professor/main.vue"),
        //   meta: { requireAuth: true, roles: ["PF"] }
        // },
        // {
        //   path: "/professorDetail/:orderId",
        //   name: "ProfessorDetail",
        //   component: () => import("../views/professor/detail.vue"),
        //   meta: { requireAuth: true, roles: ["PF"] }
        // }
      ],
      meta: { requireAuth: true }
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/login.vue")
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("../views/register.vue")
    },
    {
      path: "/",
      name: "firstPage",
      component: () => import("../views/firstPage.vue")
    }
  ]
});

//全局有效 跳转之前执行
router.beforeEach(async (to, from, next) => {
  let vm = router.app;
  // 判断该路由是否需要登录权限
  if (to.meta.requireAuth) {
    if (localStorage.token) {
      let res = await axios.post(
        `${commonCfg.commonUrl}validate${commonCfg.urlVersion}`,
        qs.stringify({ token: localStorage.token })
      );
      console.log(res.data);
      if (res.data.active == true) {
        //判断用户角色
        //let role = "";
        vm.$root.role = res.data.realm_access.roles[0];

        //if (to.meta.roles.indexOf(role) != -1) {
        //  //角色存在于有权限的角色列表里,可继续访问
        //  next();
        //} else {
        //  next(false); //拦截，使其跳回原页面
        //}
        next();
      } else {
        router.push({ path: 'login' })
      }
    } else {
      router.push({ path: 'login' })
    }
  } else {
    next();
  }
});

export default router;

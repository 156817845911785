import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import leafletCss from "leaflet/dist/leaflet.css";
import leafletDrawCss from "leaflet-draw/dist/leaflet.draw.css";
//import vueDialogDragCss from "vue-dialog-drag/dist/vue-dialog-drag.css";
//import vueDialogDragStyle from "vue-dialog-drag/dist/dialog-styles.css";
import axiosCfg from "./config/axios";
import VuetifyDialog from "vuetify-dialog";
import "vuetify-dialog/dist/vuetify-dialog.css";

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

// import VuetifyDialog from "vuetify-dialog";
// import "vuetify-dialog/dist/vuetify-dialog.css";

// Vue.use(VuetifyDialog, {
//   context: {
//     vuetify
//   }
// });
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  leafletCss,
  leafletDrawCss,
  //vueDialogDragCss,
  //vueDialogDragStyle,
  axiosCfg,
  render: h => h(App)
}).$mount("#app");
